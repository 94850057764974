export const chartTypes = [
  {
    label: "Bar Graph",
    value: "bar-graph"
  },
  {
    label: "Line Graph",
    value: "line-graph"
  },
  {
    label: "Pie Chart",
    value: "pie-chart"
  }
];
